<template>
  <div class="secondary">
    <THead :centerInfo="false"></THead>
    <div class="subnavLeft">
      <router-link to="/secondary/releaseVideo">
        <button class="releaseBtn"><span class="iconfont icon-icon_shangchuan-"></span>发布视频</button>
      </router-link>
      <ul>
        <li>
          <router-link to="/secondary/videoStatistics">
            <span class="iconfont icon-tongji"></span>视频统计
          </router-link>
        </li>
        <li>
          <router-link to="/secondary/videoAdministration">
            <span class="iconfont icon-guanli"></span>内容管理
          </router-link>
        </li>
        <li>
          <router-link to="/secondary/creativeConvention">
            <span class="iconfont icon-wenjian"></span>创作公约
          </router-link>
        </li>
      </ul>
    </div>
    <div class="secondary_content">
      <router-view />
    </div>
  </div>
</template>

<script>
  import THead from '@/components/head.vue';

  export default {
    name: "secondary",
    components: {
      THead
    },
  }
</script>

<style lang="less">
  .secondary_content {
    width: 1281px;
    // height: 815px;
    // overflow: hidden;
    background-color: #ffffff;
    border: solid 1px #eeeeee;
    // margin:97px 0px 0px 320px;
    margin: 0px auto;
    margin-top: 90px;
    padding: 40px;
    box-sizing: border-box;

  }

  @media screen and (max-width: 1600px) {
    .secondary_content {
      width: 1031px;
    }
  }
</style>